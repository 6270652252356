import axios from 'axios';

const baseUrl = 'https://api.swiftype.com/api/v1/public';

const apiClient = axios.create({
  baseUrl: baseUrl,
  withCredentials: false,
});
//const ndbs = 'https://dev-services.nelsonirrigation.com/ndbs';
const ndbs = 'https://services.nelsonirrigation.com/ndbs';
const ndbsClient = axios.create({
  baseUrl: ndbs,
  withCredentials: false,
});

const authClient = axios.create({
  baseURL: "https://auth.nelsonirrigation.com",
  //baseURL: 'https://dev-services.nelsonirrigation.com/identity',
  withCredentials: false,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    Authorization: '',
  },
});

export default {
  data () {
    return {
      token: null,
    };
  },
  async fetchToken () {
    if (this.token != null) { return this.token; }
    const params =
      'client_id=portal_client&client_secret=portalsecret&grant_type=client_credentials&scope=marketing';
    const _token = await authClient.post('/connect/token', params);
    this.token = _token.data.access_token;
    return this.token;
  },
  async getDealersForState () {
    this.token = await this.fetchToken();
    const headers = { Authorization: 'Bearer ' + this.token };
    const response = await ndbsClient.get(ndbs + '/api/Dealer', { headers });
    return response.data;
  },
  async getAllTerritories () {
    if (!this.token)
      {this.token = await this.fetchToken();}
    const headers = { Authorization: 'Bearer ' + this.token, };
    const response = await ndbsClient.get(
      ndbs + '/api/Dealer/territories',
      { headers }
    );
    return response.data;
  },
  async getAllAnnouncements () {
   // if (!this.token)
   // {this.token = await this.fetchToken();}
   // const headers = { Authorization: 'Bearer ' + this.token, };
    const response = await ndbsClient.get(
        ndbs + '/api/Announcements',
        // { headers }
    );
    return response.data;
  },
  getSearch (searchText) {
    return apiClient.get(
      baseUrl +
        '/engines/suggest.json?q=' +
        searchText +
        '&engine_key=x2UxSubcEUv62CeT8XBs&fetch_fields%5BNelson%20Marketing%20Website%5D%5B%5D=title&fetch_fields%5BNelson%20Marketing%20Website%5D%5B%5D=body&_=1699048823705',
      {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
      }
    );
  },
  getSearchEnter (searchText) {
    return apiClient.post(baseUrl + '/installs/GoVFnJ3JpydSnFHdtQjj/search.json', searchText, {
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
    });
  },
};
